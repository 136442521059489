<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Conductores</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Conductores</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Conductores</h5>
                </div>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Empresa"
                      data-toggle="tab"
                      href="#Empresa"
                      v-if="form.id"
                      >Empresa</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#Documentos"
                      v-if="form.id"
                      >Documentos</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-md-9"
                          :class="form.id ? 'col-md-9' : 'col-md-12'"
                        >
                          <div class="row">
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="tipo_documento"
                                  >Tipo identificacion</label
                                >
                                <select
                                  class="form-control form-control-sm"
                                  v-model="form.tipo_documento"
                                  :disabled="opcion == 1 || opcion == 3"
                                  :class="
                                    $v.form.tipo_documento.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="tipo_documento in listasForms.tipo_identificacion"
                                    :key="tipo_documento.numeracion"
                                    :value="tipo_documento.numeracion"
                                  >
                                    {{ tipo_documento.descripcion }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="n_identificacion"
                                  >Numero de Documento</label
                                >
                                <input
                                  type="number"
                                  :disabled="opcion == 1 || opcion == 3"
                                  class="form-control form-control-sm"
                                  id="numero_documento"
                                  v-model="form.numero_documento"
                                  :class="
                                    $v.form.numero_documento.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @blur="validaConductor()"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="nombres">Nombres</label>
                                <input
                                  type="text"
                                  :disabled="opcion == 1"
                                  class="form-control form-control-sm"
                                  id="nombres"
                                  v-model="form.nombres"
                                  @input="validarLetra(form.nombres, 1)"
                                  :class="
                                    $v.form.nombres.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="apellidos">Apellidos</label>
                                <input
                                  type="text"
                                  :disabled="opcion == 1"
                                  class="form-control form-control-sm"
                                  id="apellidos"
                                  v-model="form.apellidos"
                                  @input="validarLetra(form.nombres, 2)"
                                  :class="
                                    $v.form.apellidos.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                  type="email"
                                  :disabled="opcion == 1"
                                  class="form-control form-control-sm"
                                  id="email"
                                  v-model="form.email"
                                  :class="
                                    $v.form.email.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="celular_principal"
                                  >Celular Principal</label
                                >
                                <input
                                  type="number"
                                  :disabled="opcion == 1"
                                  class="form-control form-control-sm"
                                  id="celular_principal"
                                  v-model="form.celular_principal"
                                  :class="
                                    $v.form.celular_principal.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="celular_alterno"
                                  >Celular Alterno</label
                                >
                                <input
                                  type="number"
                                  :disabled="opcion == 1"
                                  class="form-control form-control-sm"
                                  id="celular_alterno"
                                  v-model="form.celular_alterno"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="tipo_sangre">Tipo de Sangre</label>
                                <select
                                  class="form-control form-control-sm"
                                  :disabled="opcion == 1"
                                  v-model="form.tipo_sangre"
                                  :class="
                                    $v.form.tipo_sangre.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="tipo_sangre in listasForms.tipos_sangre"
                                    :key="tipo_sangre.numeracion"
                                    :value="tipo_sangre.numeracion"
                                  >
                                    {{ tipo_sangre.descripcion }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="num_lincencia_conduccion"
                                  >Numero de Licencia</label
                                >
                                <input
                                  type="text"
                                  :disabled="opcion == 1"
                                  class="form-control form-control-sm"
                                  id="num_lincencia_conduccion"
                                  v-model="form.num_lincencia_conduccion"
                                  :class="
                                    $v.form.num_lincencia_conduccion.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="categoria_licencia"
                                  >Categoria</label
                                >
                                <select
                                  class="form-control form-control-sm"
                                  :disabled="opcion == 1 || opcion == 3"
                                  v-model="form.categoria_licencia"
                                  @change="cambiarLinea"
                                  :class="
                                    $v.form.categoria_licencia.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="categoria_licencia in listasForms.categorias_licencia"
                                    :key="categoria_licencia.numeracion"
                                    :value="categoria_licencia.numeracion"
                                  >
                                    {{ categoria_licencia.descripcion }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div
                              class="col-md-2"
                              :class="form.id ? 'col-md-2' : 'col-md-3'"
                            >
                              <div class="form-group">
                                <label for="tipo_documento">Localidad</label>
                                <select
                                  class="form-control form-control-sm"
                                  :disabled="opcion == 1 || opcion == 3"
                                  v-model="form.localidad"
                                  :class="
                                    $v.form.localidad.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="loc in listasForms.localidades"
                                    :key="loc.numeracion"
                                    :value="loc.numeracion"
                                  >
                                    {{ loc.descripcion }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div
                              class="col-md-3"
                              :class="form.id ? 'col-md-3' : 'col-md-4'"
                            >
                              <div class="form-group">
                                <label for="fecha_nacimiento"
                                  >Fecha de nacimiento</label
                                >
                                <input
                                  type="date"
                                  :disabled="opcion == 1"
                                  class="form-control form-control-sm"
                                  id="fecha_nacimiento"
                                  v-model="form.fecha_nacimiento"
                                  :class="
                                    $v.form.fecha_nacimiento.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="edad">Edad(años)</label>
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  id="edad"
                                  v-model="form.edad"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="tipo_propiedad">Propiedad</label>
                                <select
                                  :disabled="opcion == 1 || opcion == 3"
                                  class="form-control form-control-sm"
                                  id="tipo_propiedad"
                                  v-model="form.tipo_propiedad"
                                  :class="
                                    $v.form.tipo_propiedad.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="propiedad in listasForms.propiedades"
                                    :key="propiedad.numeracion"
                                    :value="propiedad.numeracion"
                                  >
                                    {{ propiedad.descripcion }}
                                  </option>
                                </select>
                                <div
                                  class="error"
                                  v-if="!$v.form.tipo_propiedad.required"
                                >
                                  Seleccione una propiedad
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-md-2 text-center">
                              <label>GNV</label>
                              <input
                                type="checkbox"
                                class="form-control form-control-sm p-0"
                                v-model="form.gnv"
                              />
                            </div>
                            <div class="form-group col-md-2 text-center">
                              <label>CV</label>
                              <input
                                type="checkbox"
                                class="form-control form-control-sm p-0"
                                v-model="form.cv"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3" v-if="form.id">
                          <label for="imagen">Foto Conductor</label>
                          <div
                            class="form-group"
                            v-if="form.link_fotografia == null"
                          >
                            <input
                              type="file"
                              :disabled="opcion == 1"
                              class="form-control-file"
                              accept="image/x-png,image/gif,image/jpeg"
                              @change="obtenerImagen"
                            />
                          </div>
                          <figure class="form-group" v-if="miniLogo">
                            <div class="button-container">
                              <img
                                :src="miniLogo"
                                alt="Logo"
                                width="236"
                                height="125"
                                class="rounded mx-auto d-block"
                              />
                              <a
                                :disabled="opcion == 1"
                                @click="destroyImage()"
                                id="BotonEliminar"
                                v-if="form.link_fotografia"
                                href="#"
                                >Eliminar <i class="fas fa-window-close"></i
                              ></a>
                            </div>
                          </figure>
                          <div class="row">
                            <div class="col-md-9"></div>
                            <div class="col-md-3" v-if="archivoValido">
                              <button
                                :disabled="opcion == 1"
                                type="button"
                                @click="saveImage()"
                                class="btn btn-success"
                                data-toggle="tooltip"
                                data-html="true"
                                title="Guardar Imagen"
                              >
                                <i class="fa fa-upload"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8 offset-md-2">
                          <ConductorLinea
                            ref="ConductorLinea"
                            v-if="
                              form.id != null &&
                                $store.getters.can(
                                  'admin.conductores.assingOrRevokeLinea'
                                )
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Empresa" v-if="form.id">
                    <ConductorEmpresa
                      v-if="
                        form.id != null &&
                          $store.getters.can(
                            'admin.conductores.assingOrRevokeEmpresa'
                          )
                      "
                    />
                  </div>
                  <div class="tab-pane fade" id="Documentos" v-if="form.id">
                    <ConductorDocumento
                      v-if="form.id != null"
                      ref="ConductorDocumento"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    :disabled="opcion == 1"
                    v-if="
                      $store.getters.can('admin.conductores.create') ||
                        $store.getters.can('admin.conductores.edit') ||
                        $store.getters.can(
                          'admin.conductores.editarTransportadora'
                        )
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import {
  required,
  numeric,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import ConductorEmpresa from "./ConductorEmpresa";
import ConductorDocumento from "./ConductorDocumento";
import ConductorLinea from "./ConductorLinea";
import Loading from "../../../../components/Loading";
import moment from "moment";

export default {
  name: "ConductorForm",
  components: {
    ConductorEmpresa,
    ConductorDocumento,
    ConductorLinea,
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {},
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      opcion: null,
      metodo: "",
      transportadora: {},
      lineaNegocio: {},
      listasForms: {
        tipo_identificacion: [],
        tipos_sangre: [],
        categorias_licencia: [],
        localidades: [],
        lineasNegocio: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      tipo_documento: {
        required,
      },
      tipo_sangre: {
        required,
      },
      numero_documento: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(11),
      },
      nombres: {
        required,
        maxLength: maxLength(50),
      },
      apellidos: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
      celular_principal: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      num_lincencia_conduccion: {
        required,
      },
      categoria_licencia: {
        required,
      },
      localidad: {
        required,
      },
      fecha_nacimiento: {
        required,
      },
      tipo_propiedad: { required },
    },
  },
  methods: {
    validarLetra(palabra, opcion) {
      if (palabra.length > 0) {
        var ascii = palabra.toUpperCase().charCodeAt(palabra.length - 1);
        if (
          !(
            (ascii > 64 && ascii < 91) ||
            ascii == 32 ||
            ascii == 193 ||
            ascii == 201 ||
            ascii == 205 ||
            ascii == 211 ||
            ascii == 218
          )
        ) {
          if (opcion == 1 || opcion == 3) {
            this.form.nombres = palabra.slice(0, -1);
          }

          if (opcion == 2) {
            this.form.apellidos = palabra.slice(0, -1);
          }
        }
      }
    },

    cambiarLinea() {
      if (this.form.categorias_licencia !== 8) {
        this.$refs.ConductorLinea.form.linea[2] = false;
        this.$refs.ConductorLinea.actualizarLinea(2);
      }
    },
    async getTiposIdentificacion() {
      await axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_identificacion = response.data;
      });
    },

    async getCategoriaLicencia() {
      await axios.get("/api/lista/17").then((response) => {
        this.listasForms.categorias_licencia = response.data;
      });
    },

    async getTiposSangre() {
      await axios.get("/api/lista/16").then((response) => {
        this.listasForms.tipos_sangre = response.data;
      });
    },

    async getLocalidades() {
      await axios.get("/api/lista/22").then((response) => {
        this.listasForms.localidades = response.data;
      });
    },

    obtenerImagen(e) {
      $("#guard_foto").css("hide");
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/admin/conductores/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_fotografia = response.data.link_fotografia;
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    async getPropiedades() {
      await axios.get("/api/lista/23").then((response) => {
        this.listasForms.propiedades = response.data;
      });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios
            .delete("/api/admin/conductores/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },

    save() {
      this.link_fotografia = this.form.link_fotografia;
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/conductores",
          data: this.form,
        })
          .then((response) => {
            this.$route.params.accion = "Editar";
            this.$route.params.data_edit = response.data;
            this.init();
            this.$swal({
              icon: "success",
              title: "Se guardo el conductor correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("1062 Duplicate entry")) {
                this.$swal({
                  icon: "error",
                  title:
                    "El numero de documento ya se encuentra creada en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la acción nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.cargando = false;
          });
      }
    },

    back() {
      return this.$router.replace("/admin/conductores");
    },

    init() {
      this.accion = this.$route.params.accion;

      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.transportadora = this.form.transportadora;
        this.miniLogo = this.uri_docs + this.form.link_fotografia;
        this.opcion = this.$route.params.opcion;
      } else {
        this.metodo = "POST";
        this.form.estado = 2;
      }
      if (this.form.id && this.form.fecha_nacimiento !== null) {
        var hoy = moment().format("YYYY");
        var fecha = moment(this.form.fecha_nacimiento).format("YYYY");
        var edad = hoy - fecha;
        this.form.edad = edad;
      }
    },

    validaConductor() {
      let params = {
        numero_documento: this.form.numero_documento,
      };
      axios
        .get("/api/admin/conductores/lista", {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.form.numero_documento = null;
            this.$swal({
              icon: "error",
              title:
                "El numero de documento digitado ya se encuentra creado...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getTiposIdentificacion();
    await this.getTiposSangre();
    await this.getCategoriaLicencia();
    await this.getLocalidades();
    await this.getPropiedades();
    await this.init();
    this.cargando = false;
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
